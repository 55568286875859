import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M32 12c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20m0 3.168c9.281 0 16.832 7.551 16.832 16.832S41.281 48.832 32 48.832 15.168 41.281 15.168 32 22.719 15.168 32 15.168M31 33V20h3v14l-6.268 6.889-2.12-2.121L31 33z"
    />
  </svg>,
  'Clock',
);
