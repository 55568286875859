import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      d="M32 12a20 20 0 1 0 0 40 20 20 0 0 0 0-40m0 3.17c9.28 0 16.83 7.55 16.83 16.83 0 9.28-7.55 16.83-16.83 16.83S15.17 41.28 15.17 32 22.72 15.17 32 15.17zM30 36h4V22h-4v14zm1.9 1.86a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2z"
    />
  </svg>,
  'Exclamation',
);
