import React from 'react';
import { svgIcon } from 'utils/svg-icon';
import { colourPrimaryGrey } from '@johnlewispartnership/wtr-ingredients/foundations/colours';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill={colourPrimaryGrey}
      fillRule="evenodd"
      d="M64 46.4c0 3.52-2.88 6.4-6.4 6.4H6.4c-3.52 0-6.4-2.88-6.4-6.4V17.6c0-3.52 2.88-6.4 6.4-6.4h51.2c3.52 0 6.4 2.88 6.4 6.4zM14.23 29.787a397.345 397.345 0 0 1 1.274 2.955h-2.475a132.983 132.983 0 0 0 1.201-2.955zm-.7-1.891l-3.527 8.187h1.611c.467-1.091.772-1.811.912-2.16h3.492l.945 2.16h1.611a2836.642 2836.642 0 0 0-3.619-8.187zm18.872.584a7.952 7.952 0 0 0-1.532-.5 6.66 6.66 0 0 0-1.472-.165c-.675 0-1.29.104-1.843.315a3.664 3.664 0 0 0-1.438.922 4.272 4.272 0 0 0-.922 1.355 4.357 4.357 0 0 0-.304 1.646c0 .577.102 1.11.304 1.6.208.491.508.94.888 1.344.413.397.888.698 1.424.9a6.08 6.08 0 0 0 1.88.29c.56 0 1.101-.054 1.623-.16a8.931 8.931 0 0 0 1.553-.504v-3.597H29.68v1.214h1.4v1.472a6.962 6.962 0 0 1-.842.211c-.255.049-.495.07-.721.07-.429 0-.829-.07-1.205-.21a3 3 0 0 1-.968-.62 2.762 2.762 0 0 1-.62-.923 2.973 2.973 0 0 1-.21-1.12c0-.404.071-.778.21-1.122.157-.342.367-.652.633-.934.28-.296.606-.512.98-.643.373-.14.785-.208 1.237-.208.498-.006.981.06 1.448.198.484.125.941.327 1.38.607zm3.086 7.603h1.576v-8.152h-1.576zm4.547-8.152v8.152h1.576v-3.635h3.235V31.2h-3.235v-2.032h3.235v-1.237zm6.64 0v1.332h2.861v6.82h1.576v-6.82h2.885V27.93h-7.323z"
    />
  </svg>,
  'GiftCard',
);
