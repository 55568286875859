import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M50 34v16h-3V37H17v13h-3V34h36zM32 14a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 3.2a5.8 5.8 0 0 0-5.8 5.8 5.8 5.8 0 0 0 5.8 5.8 5.8 5.8 0 0 0 5.8-5.8 5.8 5.8 0 0 0-5.8-5.8z"
    />
  </svg>,
  'Account',
);
