import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M41.832 12L20 31.96 41.911 52l2.054-2.246L24.51 31.961l19.376-17.715z"
    />
  </svg>,
  'ChevronLeft',
);
