// THESE ARE LEGACY ICONS. DO NOT ADD TO THIS LIST!!!
import addNote from './addNote.svg';
import exclamationCircle from './exclamationCircle.svg';
import mywaitroseCard from './mywaitroseCard.svg';
import spinner from './spinner.svg';
import success from './success.svg';
import tick from './tick.svg';

export default {
  addNote,
  exclamationCircle,
  mywaitroseCard,
  spinner,
  success,
  tick,
};
