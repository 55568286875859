import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" role="img">
    <title>Visa</title>
    <path
      fill="#292E66"
      fillRule="evenodd"
      d="M31.8 26.23l-2.67 12.5H25.9l2.67-12.5h3.24zm13.6 8.07l1.7-4.7.98 4.7H45.4zm3.61 4.42h3l-2.62-12.5h-2.76a1.47 1.47 0 0 0-1.38.92L40.4 38.72h3.4l.67-1.86h4.15l.4 1.86zm-8.44-4.08c.02-3.3-4.56-3.48-4.52-4.95 0-.45.43-.92 1.37-1.05a6.1 6.1 0 0 1 3.18.56l.57-2.65a8.72 8.72 0 0 0-3.02-.55c-3.2 0-5.45 1.7-5.46 4.13-.03 1.8 1.6 2.8 2.82 3.4 1.26.61 1.69 1 1.68 1.55 0 .84-1 1.21-1.93 1.23-1.63.02-2.57-.44-3.32-.8l-.59 2.75c.76.34 2.15.64 3.6.66 3.4 0 5.61-1.68 5.62-4.28zM27.2 26.23l-5.24 12.5h-3.42l-2.57-9.98c-.16-.61-.3-.84-.77-1.1A13.5 13.5 0 0 0 12 26.6l.08-.36h5.5c.7 0 1.33.47 1.49 1.27l1.36 7.23 3.36-8.5h3.4z"
    />
  </svg>,
  'PaymentVisa',
);
