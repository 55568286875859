import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import icons from './icons';
import iconsOld from './old/svgIcons';
import styles from './Icon.scss';

const Icon = ({ className, isAnimated, name }) => {
  const cx = classNames.bind(styles);

  if (icons[name] || iconsOld[name]) {
    let icon;

    if (iconsOld[name]) {
      icon = (
        <i
          className={cx('iconOld', name, className)}
          data-testid="icon-wrapper-old"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: iconsOld[name] }}
        />
      );
    } else {
      const IconSvg = icons[name];
      icon = (
        <i className={cx('icon', name, className)} data-testid="icon-wrapper">
          <IconSvg focusable="false" />
        </i>
      );
    }

    return isAnimated ? (
      <TransitionGroup>
        <CSSTransition
          classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
            exitDone: styles.exitDone,
          }}
          key={name}
          timeout={{ enter: 100, exit: 0 }}
        >
          {icon}
        </CSSTransition>
      </TransitionGroup>
    ) : (
      icon
    );
  }

  return null;
};

const iconsList = [...Object.keys(icons), ...Object.keys(iconsOld)];

Icon.propTypes = {
  className: PropTypes.string,
  isAnimated: PropTypes.bool,
  name: PropTypes.oneOf(iconsList).isRequired,
};

Icon.defaultProps = {
  className: null,
  isAnimated: false,
};

export default Icon;
