import Account from './Account';
import Apple from './Apple';
import AmericanExpress from './PaymentAmericanExpress';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import Clock from './Clock';
import Exclamation from './Exclamation';
import GiftCard from './GiftCard';
import GiftVoucher from './GiftVoucher';
import Google from './Google';
import Maestro from './PaymentMaestro';
import Mastercard from './PaymentMastercard';
import Outlook from './Outlook';
import PartnershipMastercard from './PaymentPartnershipMastercard';
import PaymentCard from './PaymentCard';
import Present from './Present';
import RemoveOutline from './RemoveOutline';
import Success from './Success';
import Visa from './PaymentVisa';
import Yahoo from './Yahoo';

export default {
  Account,
  Apple,
  AmericanExpress,
  BusinessAccountCard: Maestro,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Clock,
  Exclamation,
  GiftCard,
  GiftVoucher,
  Google,
  Maestro,
  Mastercard,
  Outlook,
  PartnershipMastercard,
  PaymentCard,
  Present,
  RemoveOutline,
  Success,
  Visa,
  Yahoo,
};
