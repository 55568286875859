import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M32 12c-11.045 0-20 8.955-20 20 0 11.046 8.955 20 20 20 11.046 0 20-8.954 20-20 0-11.045-8.954-20-20-20m0 3.168c9.28 0 16.832 7.55 16.832 16.832 0 9.28-7.551 16.832-16.832 16.832S15.168 41.28 15.168 32 22.718 15.168 32 15.168m-.193 18.95l-7.272 7.272-2.122-2.12 7.273-7.274-7.424-7.424 2.424-2.425 7.424 7.425 7.273-7.274 2.121 2.121-7.272 7.274 7.425 7.425-2.425 2.424-7.425-7.425z"
    />
  </svg>,
  'RemoveOutline',
);
