import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="svg12" viewBox="0 0 64 64">
    <path
      style={{ fill: '#00adef', strokeWidth: 0.731764 }}
      d="M 0,9.0613391 26.154771,5.5103054 26.166201,30.660965 0.02388933,30.809379 Z M 26.142312,33.55881 26.162612,58.731389 0.02030015,55.14828 0.01883435,33.389961 Z M 29.312883,5.0457675 63.991938,0 V 30.341093 L 29.312883,30.615556 Z M 64,33.795536 63.9919,64 29.312844,59.120525 29.264254,33.738985 Z"
      id="path10"
    />
  </svg>,
  'Outlook',
);
