import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      d="M12 46h40V19H12v27zm3-15h34v12H15V31zm0-6v-3h34v3H15zm3 15h6v-6h-6v6z"
    />
  </svg>,
  'PaymentCard',
);
