import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M14.246 20L12 22.054l20.039 21.911 19.96-21.832v-.002l-2.245-2.053-17.715 19.376z"
    />
  </svg>,
  'ChevronDown',
);
