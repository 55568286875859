import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 64 64">
    <g id="XMLID_1_" transform="matrix(1.1494253,0,0,1.1636364,-23.229885,-23.854546)">
      <path
        id="XMLID_3_"
        d="m 51,52.1 v 0 c 6,-10.6 16,-27.9 18.8,-31.6 -1.2,0.8 -3,1.2 -4.7,1.6 L 62.5,20.5 C 60.4,24.3 52.8,36.8 48,44.7 43.1,36.6 37.3,27.2 33.5,20.5 c -3,0.6 -4.3,0.7 -7.2,0 6,9 15.5,26 18.7,31.6 l -0.4,23.4 3.5,-1.6 v 0 l 3.5,1.6 z"
      />
    </g>
  </svg>,
  'Yahoo',
);
