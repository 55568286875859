import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      d="M32 12a20 20 0 1 0 0 40 20 20 0 0 0 0-40m0 3.17c9.28 0 16.83 7.55 16.83 16.83S41.28 48.83 32 48.83A16.85 16.85 0 0 1 15.17 32c0-9.28 7.55-16.83 16.83-16.83m-2.56 25.99L22.7 34.3l2.14-2.1 4.45 4.52 10.18-11.81 2.27 1.95-12.3 14.29z"
    />
  </svg>,
  'Success',
);
