import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
    <g transform="translate(12.000000, 12.000000)">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M33.9,9.2 C34.4,8.3 34.7,7.2 34.7,6.1 C34.4,2.6 31.5,0 28,0 C28,0 28,0 27.9,0 C23.4,0 21.1,3.5 20,6 C18.9,3.5 16.6,0 12.1,0 C12.1,0 12.1,0 12,0 C8.5,0 5.6,2.6 5.2,6.2 C5.2,7.3 5.5,8.3 6,9.2 L0,9.2 L0,22.9 L3.3,22.9 L3.3,40 L36.7,40 L36.7,22.9 L40,22.9 L40,9.2 L33.9,9.2 Z M8.4,6.2 C8.7,4.4 10.2,3.1 12,3.1 C12,3.1 12,3.1 12.1,3.1 C15.6,3.1 17.1,6.8 17.8,9.2 L11.5,9.2 C9.8,9.2 8.4,7.9 8.4,6.2 Z M18.4,22.9 L18.4,36.9 L6.4,36.9 L6.4,22.9 L18.4,22.9 Z M3.1,19.7 L3.1,12.3 L18.4,12.3 L18.4,19.7 L3.1,19.7 L3.1,19.7 Z M21.6,36.9 L21.6,22.9 L33.6,22.9 L33.6,36.9 L21.6,36.9 Z M22.2,9.2 C23,6.4 24.6,3.1 27.9,3.1 L27.9,3.1 C27.9,3.1 27.9,3.1 28,3.1 C29.8,3.1 31.3,4.4 31.6,6.1 C31.6,7.8 30.2,9.1 28.6,9.1 L22.2,9.1 L22.2,9.2 Z M36.9,12.4 L36.9,19.8 L21.6,19.8 L21.6,12.4 L36.9,12.4 Z"
      />
    </g>
  </svg>,
  'Present',
);
