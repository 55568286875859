import React from 'react';
import { svgIcon } from 'utils/svg-icon';
import { colourChiaGrey } from '@johnlewispartnership/wtr-ingredients/foundations/colours';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill={colourChiaGrey}
      fillRule="evenodd"
      d="M19.6 16v1.6h.8V16H64v32H20.4v-1.6h-.8V48H0V16zm-6.55 21.157v-1.65H8.832c-.112-.563-.2-1.062-.262-1.5a9.237 9.237 0 0 1-.093-1.218c0-.576.176-.995.525-1.258.348-.275.868-.411 1.555-.411.475-.013.963.069 1.462.243.501.175 1.026.437 1.576.787v-1.93a6.149 6.149 0 0 0-1.481-.6 5.709 5.709 0 0 0-1.557-.209c-.56 0-1.08.077-1.555.227a3.597 3.597 0 0 0-1.22.693 2.803 2.803 0 0 0-.787 1.032 3.453 3.453 0 0 0-.262 1.368c0 .314.037.707.112 1.183.075.448.176.98.3 1.593H6.078v1.648h1.387c.05.301.086.589.112.864.026.263.038.519.038.768 0 .602-.13 1.133-.395 1.595a3.608 3.608 0 0 1-1.161 1.181v1.295a9.06 9.06 0 0 1 1.425-.357c.464-.075.9-.112 1.312-.112a9.08 9.08 0 0 1 1.856.243c.426.075.775.131 1.05.168.3.038.525.058.675.058.376 0 .72-.039 1.032-.114.325-.086.618-.187.88-.3v-1.687a5.856 5.856 0 0 1-.862.3 4.736 4.736 0 0 1-1.05.113c-.187 0-.43-.02-.731-.056a15.58 15.58 0 0 0-.975-.15 16.453 16.453 0 0 0-.937-.17 3.196 3.196 0 0 0-.656-.037h-.245a.349.349 0 0 0-.206.019c.225-.338.384-.669.468-.994.114-.337.164-.668.15-.993.014-.301 0-.583-.036-.845a14.784 14.784 0 0 0-.094-.787h3.937zm6.55-15.01h.8v-3.032h-.8zm0 4.549h.8v-3.034h-.8zm0 4.546h.8V28.21h-.8zm0 4.547h.8v-3.032h-.8zm0 4.547h.8v-3.032h-.8zm0 4.549h.8v-3.032h-.8z"
    />
  </svg>,
  'GiftVoucher',
);
