import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M22.132 12l-2.054 2.245 19.376 17.716L20 49.754 22.054 52l21.911-20.04z"
    />
  </svg>,
  'ChevronRight',
);
