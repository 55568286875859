import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64.000001" id="svg18">
    <path
      d="m 63.95,32.717602 c 0,-2.175197 -0.179927,-4.362153 -0.563771,-6.502077 H 32.594738 v 12.322201 h 17.632837 c -0.731703,3.974145 -3.082748,7.489735 -6.525349,9.723722 v 7.995321 H 54.221952 C 60.399442,50.68356 63.95,42.453082 63.95,32.717602 Z"
      fill="#4285f4"
      id="path10"
      style={{ strokeWidth: 0.118759 }}
    />
    <path
      d="m 32.594738,63.98166 c 8.804423,0 16.229407,-2.833636 21.639209,-7.724891 L 43.714221,48.261448 c -2.926811,1.951799 -6.705276,3.057034 -11.107488,3.057034 -8.51654,0 -15.737606,-5.631998 -18.328554,-13.204038 H 3.4225893 V 46.35668 C 8.9643379,57.162121 20.251752,63.98166 32.594738,63.98166 Z"
      fill="#34a853"
      id="path12"
      style={{ strokeWidth: 0.118759 }}
    />
    <path
      d="m 14.266184,38.114444 c -1.367444,-3.974145 -1.367444,-8.277509 0,-12.251654 V 17.620555 H 3.4225893 c -4.6301191,9.041767 -4.6301191,19.694357 0,28.736125 z"
      fill="#fbbc04"
      id="path14"
      style={{ strokeWidth: 0.118759 }}
    />
    <path
      d="m 32.594738,12.646994 c 4.654109,-0.07055 9.152282,1.646096 12.522913,4.797193 l 9.320213,-9.1358301 c -5.901602,-5.4321154 -13.73442,-8.41860301 -21.843126,-8.3245404 -12.342986,0 -23.6304001,6.8195387 -29.1721487,17.6367385 L 14.266184,25.86279 c 2.578952,-7.583797 9.812014,-13.215796 18.328554,-13.215796 z"
      fill="#ea4335"
      id="path16"
      style={{ strokeWidth: 0.118759 }}
    />
  </svg>,
  'Google',
);
