import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="64" viewBox="0 0 64 64" height="64" id="svg2">
    <path
      d="m 58.847285,49.87596 c -0.987468,2.235981 -2.156309,4.294189 -3.510552,6.186479 -1.845963,2.579671 -3.357395,4.365297 -4.522205,5.356874 -1.805657,1.627604 -3.74029,2.461158 -5.81196,2.508566 -1.487249,0 -3.280815,-0.414805 -5.368606,-1.256256 -2.094643,-0.837512 -4.019603,-1.25231 -5.779717,-1.25231 -1.845962,0 -3.825737,0.414798 -5.943353,1.25231 -2.120844,0.841451 -3.829365,1.279959 -5.135646,1.323415 -1.986627,0.08296 -3.966803,-0.774298 -5.943354,-2.575725 C 15.570351,60.340825 13.992416,58.491991 12.102118,55.87281 10.073978,53.075862 8.4065663,49.832504 7.1002859,46.134838 5.7013045,42.140885 5,38.273348 5,34.529068 5,30.240013 5.945552,26.540767 7.8394769,23.440811 c 1.4884583,-2.489997 3.4686361,-4.454185 5.9469801,-5.896117 2.478346,-1.441931 5.156201,-2.176723 8.040014,-2.223735 1.577934,0 3.647187,0.478405 6.218635,1.418624 2.564195,0.943379 4.210649,1.421784 4.932508,1.421784 0.539682,0 2.368716,-0.559391 5.469369,-1.674615 2.932179,-1.034241 5.406896,-1.462476 7.43423,-1.29379 5.493553,0.434555 9.62077,2.557158 12.365531,6.381239 -4.913162,2.917838 -7.343545,7.004628 -7.29518,12.247332 0.04434,4.083629 1.555767,7.481846 4.526235,10.180038 1.346181,1.252306 2.849554,2.220178 4.522201,2.907565 -0.362739,1.031079 -0.745635,2.018704 -1.152715,2.966824 z M 46.247989,1.2808917 c 0,3.2007308 -1.193026,6.1892439 -3.57101,8.9553803 -2.869708,3.288396 -6.34076,5.188584 -10.104832,4.888742 -0.04796,-0.383989 -0.07577,-0.788124 -0.07577,-1.212802 0,-3.072698 1.364723,-6.3610918 3.788255,-9.0498006 C 37.494579,3.5010711 39.033418,2.369135 40.899534,1.4661681 42.761618,0.57667409 44.52294,0.08476472 46.179468,5.2734376e-4 46.227837,0.42841504 46.247989,0.85632966 46.247989,1.2808502 Z"
      id="path4"
      style={{ strokeWwidth: 0.0544515 }}
    />
  </svg>,
  'Apple',
);
